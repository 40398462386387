
import Vue from 'vue'
import Component from 'vue-class-component'
// import { Http } from '@/apis/Http'
import Stat from '@/apis/Stat'
import moment from 'moment'
import { sprintf } from 'sprintf-js'

@Component({
  name: 'Stat'
})

export default class AdminSuppors extends Vue {
  search='';
  hasUnswer = false;
  statList = {
    headers: [],
    items: []
  };

  loading = false
  totalItems = 0
  currentPage = 1
  prevPage = 1
  itemsPerPage = 9999
  pageCount = 1

  requestDate(date: any): string {
    return moment(date).format('DD.MM.YYYY HH:mm')
  }

  timer(start: any, end: any) {
    const diff = (end - start) / 1000
    return sprintf('%02d:%02d', Math.floor(diff / 3600), (diff / 60) % 60)
  }

  created() {
    // @ts-ignore
    this.$setLoading(true)
    this.getStatListLc()
  }

  navigate() {
    if (this.currentPage === this.prevPage) return
    // @ts-ignore
    this.$setLoading(true)
    this.getStatListLc()
  }

  getStatListLc() {
    const params = {
      limit: this.itemsPerPage,
      offset: (this.currentPage - 1) * this.itemsPerPage,
      page: this.currentPage
    }

    this.loading = true
    Stat.fetchStatListLc(params)
      .then(({ data }) => {
        // this.statList.items = data.items.data
        this.statList.items = data.items.data
        this.statList.headers = data.headers
        // @ts-ignore
        this.$setLoading(false)
        // this.isLoading = false

        // this.totalItems = data.total
        // this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
        // this.prevPage = parseInt(this.currentPage)
      })
      .finally(() => { this.loading = false })
  }
}
